<template>
    <div class="ts-table flex flex-item flex-column">
        <ts-table-edit ref="table" class="make-table flex-item" :cols="cols" :data="list" :page-param="pageParam"
            :loading="loading" @handleSort="sort" full :cmds="cmds" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" @setSearchClose="setSearchClose"
            @setHandleFilter="setHandleFilter" :searchParam="searchParam" :table-name="tableName"
            :isSearchLeft="isSearchLeft" :searchCols="searchCols">

            <div class="page-right" slot="page">
                <div class="static-data page" v-if="staticData.ownNumber">
                    本人已提交：<div class="red">{{ staticData.ownNumber }}</div>审核通过：<div class="red">
                        {{ staticData.ownPassNumber }}</div>获得积分：<div class="red">{{ staticData.integralNumber }}</div>
                </div>
            </div>
        </ts-table-edit>

        <edit-drawer :show.sync="dialog.show" :loading="dialog.loading" @save="save" v-if="dialog.show" :dialog="dialog"
            :title="title + (dialog.edit ? '修改' : '新增')" :cols="cols" :form="dialog.form">
            <span class="edit-drawer_assist" slot="title" v-if="dialog.isExist">该条数据已存在，请修改</span>
        </edit-drawer>
    </div>
</template>

<script>
import TsTableEdit from './TsTableEdit'
import EditDrawer from './EditDrawer'
import commonMixin from './commonMixin'
import Vue from 'vue'
export default {
    props: ['visitId', 'searchParam'],
    components: { TsTableEdit, EditDrawer },
    mixins: [commonMixin],
    data() {
        return {
            api: {
                page: 'sys/duInstructionBooks/queryDuInstructionBooksPage',
                add: 'sys/duInstructionBooks/insertDuInstructionBooks',
                modify: 'sys/duInstructionBooks/updateDuInstructionBooks',
                del: 'sys/duInstructionBooks/deleteById',
                static: 'sys/duInstructionBooks/staticArticle',
                exist: 'sys/duInstructionBooks/haveSame',
            },
            staticCols: [
                {
                    field: 'list',
                    title: '统计数量',
                    showField: 'key',
                },
            ],
            cols: [
                {
                    key: 'compCode',
                    showKey: 'compName',
                    searchKey: 'compName',
                    value: '企业',
                    width: 150,
                    type: 'fuzzy',
                    required: true,
                    search: true,
                    queryType: this.$variable.searchType.SELECT,
                    param: {
                        api: 'setting/aimBaseComp/queryAimBaseCompListByKeyword',
                        keyField: 'compCode',
                        valueField: 'compName',
                        assignment(formObj, toObj) {
                            Vue.set(toObj, 'compCode', formObj.compCode)
                            Vue.set(toObj, 'compName', formObj.compName)
                        },
                    }
                },
                {
                    key: 'comCode',
                    showKey: 'comName',
                    searchKey: 'comName',
                    value: '药品通用名',
                    required: true,
                    search: true,
                    width: 150,
                    type: 'fuzzy',
                    queryType: this.$variable.searchType.SELECT,
                    param: {
                        api: 'setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword',
                        keyField: 'comCode',
                        valueField: 'comName',
                        assignment(formObj, toObj) {
                            Vue.set(toObj, 'comCode', formObj.comCode)
                            Vue.set(toObj, 'comName', formObj.comName)
                        },
                    }
                },
                { key: 'comNameEn', value: '英文通用名', search: true, width: 150 },
                { key: 'tradeName', value: '商品名', search: true },
                { key: 'spec', value: '规格', required: true, search: true },
                { key: 'usageDosage', value: '用法用量', type: 'textarea', search: true ,required: true,},
                { key: 'component', value: '成分', search: true,required: true, },
                { key: 'indication', value: '适应症', type: 'textarea', search: true,required: true,},
                { key: 'adverseReactions', value: '不良反应', type: 'textarea', },
                { key: 'contrainDications', value: '禁忌', type: 'textarea', },
                { key: 'precautions', value: '注意事项', type: 'textarea' },
                { key: 'useInPreglact', value: '孕妇及哺乳期妇女用药', width: 180, type: 'textarea' },
                { key: 'useInChildren', value: '儿童用药', type: 'textarea' },
                { key: 'useInElderly', value: '老年用药', type: 'textarea' },
                { key: 'drugInteractions', value: '药物相互作用', width: 150, type: 'textarea' },
                { key: 'overdosage', value: '药物过量', type: 'textarea' },
                { key: 'clinicalTrial', value: '临床试验', type: 'textarea' },
                { key: 'poison', value: '毒理研究', type: 'textarea' },
                { key: 'mechanismAction', value: '药理作用', type: 'textarea' },
                { key: 'pharmacokinetics', value: '药代动力学', width: 150, type: 'textarea' },
                { key: 'description', value: '性状' },
                { key: 'storage', value: '贮藏' },
                { key: 'pack', value: '包装' },
                { key: 'period', value: '有效期' },
                { key: 'standard', value: '执行标准' },
                { key: 'approvalNumber', value: '批准文号', },
                { key: 'otc', value: 'OTC' },
                { key: 'cateName', value: '药物分类' },
                { key: 'approveDate', value: '核准日期', type: 'date', width: 120 },
                { key: 'modifyDate', value: '修改日期', type: 'date', width: 120 },
                { key: 'warning', value: '警示' },
                { key: 'attachment', value: 'PDF上传', type: 'file' },
                { key: 'failReason', value: '不通过原因', width: 200, hEdit: true },
                {
                    width: 120,
                    key: 'status',
                    showKey: 'statusName',
                    value: '审核状态',
                    fixed: 'right',
                    hEdit: true
                },
            ],
            existParam: {
                colKeys: ['comCode', 'compCode', 'spec', 'approveDate', 'modifyDate'],
            },
            cmds: [
                {
                    txt: '修改',
                    type: 'primary',
                    event: this.modify,
                    whether(row) {
                        return row.status !== 1
                    },
                },
                {
                    txt: '删除',
                    type: 'danger',
                    event: this.del,
                    whether(row) {
                        return row.status !== 1
                    },
                }
            ],
            tableName: 'du_instruction_books',
        }
    },
    watch: {
        'dialog.form.comCode'() {
            this.isExist()
        },
        'dialog.form.compCode'() {
            this.isExist()
        },
        'dialog.form.spec'() {
            this.isExist()
        },
        'dialog.form.approveDate'() {
            this.isExist()
        },
        'dialog.form.modifyDate'() {
            this.isExist()
        },
    },
    methods: {
        formatStatic(data) {
            data.list = [
                { key: '企业数量', value: data.duInstructionBooksCompNumber },
                { key: '药品数量', value: data.duInstructionBooksComNumber },
            ]
            return data
        },
    }
}
</script>